import React from 'react'
import ConfirmationScreen from './screens/ConfirmationScreen'
import { Switch, Route, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

const AppNavigator = ({ auth }) => {
  const authLoaded = isLoaded(auth)
  if (!authLoaded) {
    return <div style={{ flex: 1 }}>Wait</div>
  } else {
    return (
      <Switch>
        <Route path={'/created/'}>
          <ConfirmationScreen process={'created'} />
        </Route>
        <Route path={'/auth/'}>
          <ConfirmationScreen process={'default'} />
        </Route>
        <Route path={'/done/'}>
          <ConfirmationScreen process={'done'} />
        </Route>
      </Switch>
    )
  }
}

export default enhance(AppNavigator)
