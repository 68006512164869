import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../useWindowsDimensions'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useLocation } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function CintoiaLogo(props) {
  return (
    <div className='logo'>
      <img src='https://www.cintoia.com/img/logo.png' alt='C' className='brandlogo' />
      <span className='brandtext'>CINTOIA</span>
    </div>
  )
}

const theme = createTheme()

function ConfirmationScreen({ process }) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  let query = useQuery()
  let _reUrl = query.get('continueUrl')
    ? query.get('continueUrl')
    : query.get('d')
    ? 'https://' + query.get('d') + '.cintoia.com/login/'
      : 'https://account.cintoia.com/done/'
  
  const [screen, setScreen] = useState('resetPassword') // recoverEmail, verifyEmail
  const [mode, setMode] = useState(query.get('mode'))
  const [actionCode, setActionCode] = useState(query.get('oobCode'))
  const [email, setEmail] = useState(null)
  const [language, setLanguage] = useState(query.get('lang'))
  const [redirectUrl, setRedirectUrl] = useState(_reUrl)
  const [managedDone, setManagedDone] = React.useState(false)
  const [resetDone, setResetDone] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [initError, setInitError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(false)
  const [password, setPassword] = useState()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    async function fetchData() {
      try {
        const email = await firebase.auth().verifyPasswordResetCode(actionCode)
        setEmail(email)
      } catch (error) {
        console.error(error)
        setErrorMessage(t(error.code))
        setOpenError(true)
        setInitError(true)
      }
    }
    fetchData()
  }, [])

  const handleCloseError = () => {
    setOpenError(false)
  }

  const handleResetSubmit = async (event) => {
    event.preventDefault()
    try {
      const resp = await firebase.auth().confirmPasswordReset(actionCode, password)
      setResetDone(true)
      if (redirectUrl) {
        setTimeout(() => {
          window.location.replace(redirectUrl)
        }, 1000)
      }
    } catch (error) {
      console.error(error)
      setErrorMessage(t(error.code))
      setOpenError(true)
    }
  }

  const handleSetSubmit = async (event) => {
    event.preventDefault()
    try {
      const resp = await firebase.auth().confirmPasswordReset(actionCode, password)
      setManagedDone(true)
      if (redirectUrl) {
        setTimeout(() => {
          window.location.replace(redirectUrl)
        }, 1000)
      } 
    } catch (error) {
      console.error(error)
      setErrorMessage(t(error.code))
      setOpenError(true)
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
      // auth/expired-action-code

      // auth/invalid-action-code

      // auth/user-disabled

      // auth/user-not-found

      // auth/weak-password
    }
  }

  if (process === 'created') {
    // render notification + set password
    const isValidForm = password && password.length > 6
    return (
      <ThemeProvider theme={theme}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CintoiaLogo />
            {managedDone ? (
              <Typography sx={{ mt: 2, textAlign: 'center' }} variant='body1'>
                Salasana asetettu. Siirrytään osoitteeseen { redirectUrl }
              </Typography>
            ) : initError ? (
              <></>
            ) : (
              <React.Fragment>
                <Typography component='h1' variant='h6'>
                  {query.get('name')}
                </Typography>
                <Typography component='h1' variant='h5'>
                  {t('password')}
                </Typography>
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant='body1'>
                  Tunnukselle <b>{email}</b> on rekisteröity tunnus {query.get('name')} toimesta. Aseta salasana
                  tunnukselle.
                </Typography>
                <Box component='form' onSubmit={handleSetSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    error={password && password.length < 7}
                    name='password'
                    label={t('password') + ' (' + t('password_minimum') + ')'}
                    type='password'
                    id='password'
                    autoComplete='new-password'
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <Button type='submit' disabled={!isValidForm} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                    {t('save')}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
          <Dialog
            fullScreen={fullScreen}
            open={openError}
            onClose={handleCloseError}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogContent>
              <DialogContentText>{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseError}>
                {t('close')}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </ThemeProvider>
    )
  } else if (process === 'default') {
    if (mode === 'verifyEmail') {
      return (
        <div>
          {query.get('name')} {process}{' '}
        </div>
      )
    } else if (mode === 'resetPassword') {
      const isValidForm = password && password.length > 6
      return (
        <ThemeProvider theme={theme}>
          <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CintoiaLogo />
              {resetDone ? (
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant='body1'>
                  Salasana asetettu. Siirrytään osoitteeseen { redirectUrl }
                </Typography>
              ) : initError ? (
                <></>
              ) : (
                <React.Fragment>
                  <Typography sx={{ mt: 3 }} component='h1' variant='h5'>
                    {t('set_password')}
                  </Typography>
                  <Typography variant='body1'>
                    <b>{email}</b>
                  </Typography>

                  <Box component='form' onSubmit={handleResetSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      error={password && password.length < 7}
                      name='password'
                      label={t('password') + ' (' + t('password_minimum') + ')'}
                      type='password'
                      id='password'
                      autoComplete='new-password'
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <Button type='submit' disabled={!isValidForm} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                      {t('save')}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Box>
            <Dialog
              fullScreen={fullScreen}
              open={openError}
              onClose={handleCloseError}
              aria-labelledby='responsive-dialog-title'
            >
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleCloseError}>
                  {t('close')}
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        </ThemeProvider>
      )
    }
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CintoiaLogo />
            <Typography sx={{ mt: 2, textAlign: 'center' }} variant='body1'>
              Salasana asetettu. Voit siirtyä varausjärjestelmään kirjautuaksesi uudella salasanalla.
            </Typography>
          </Box>
        </Container>
      </ThemeProvider>
    )
  }
}
export default ConfirmationScreen
