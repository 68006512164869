
export const firebaseConfig = {
  apiKey: 'AIzaSyB_aqXQI6DuVinrjeKX7doEHWEd-dIN1mQ',
  authDomain: 'falcon-328a1.firebaseapp.com',
  databaseURL: 'https://falcon-328a1.firebaseio.com',
  projectId: 'falcon-328a1',
  storageBucket: 'falcon-328a1.appspot.com',
  messagingSenderId: '640167253821',
  appId: '1:640167253821:web:8bd0d11aa501d630a59754',
  measurementId: 'G-5YLCC5G2WK',
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true
}
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { firebaseConfig, rrfConfig }
