import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
// import 'firebase/analytics'
import 'firebase/performance'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import createStore from './createStore'
import { firebaseConfig, rrfConfig } from './config'
import { createFirestoreInstance } from 'redux-firestore'
import './i18n'

try {
  firebase.initializeApp(firebaseConfig)
  firebase.performance()
} catch (err) {}

const store = createStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>
)
