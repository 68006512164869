import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppNavigator from './AppNavigator'
// import FirebaseAnalytics from './FirebaseAnalytics'
// import CookieConsent from 'react-cookie-consent'
import { useFirebase, isLoaded } from 'react-redux-firebase'
import './App.css'

function App() {
  return (
    <BrowserRouter>
      <AppNavigator />
    </BrowserRouter>
  )
}

export default App
